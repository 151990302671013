import {Component, OnInit} from '@angular/core';
import {environment} from '../environments/environment';

@Component({
  selector: 'toycc-root',
  templateUrl: './app.component.html',
  standalone: false
})
export class AppComponent implements OnInit{
  constructor() {
    if (!environment.logEnabled) {
      console.log = () => {};
    }
  }
  ngOnInit() {
    if (!(environment.userlaneKey === undefined)) {
      this.loadUserlaneScript();
    }
  }

  public loadUserlaneScript() {
    const body = document.body as HTMLDivElement;
    const script = document.createElement('script');
    script.text = '(function(i,s,o,g,r,a,m){i[\'UserlaneCommandObject\']=r;i[r]=i[r]||function(){(i[r].q=i[r].q||[]).push(arguments)};a=s.createElement(o),m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)})(window,document,\'script\',\'https://cdn.userlane.com/userlane.js\',\'Userlane\');';
    body.appendChild(script);
  }
}
