import { Component } from '@angular/core';
import {ActivatedRoute} from "@angular/router";

export class ErrorPage {
  static notFound = {
    code: '404',
    message: 'Diese Seite konnten wir leider nicht finden.'
  }

  static noSalesperson = {
    code: '403',
    message: 'Wir konnten keinen gültigen Nutzer/Verkäufer finden.'
  }

  static customerUnavailable = {
    code: '451',
    message: 'Aufgrund rechtlicher Bestimmungen ist dieser Kunde nicht verfügbar.'
  }

  static sessionExpired = {
    code: '401',
    message: 'Ihre Sitzung ist abgelaufen. Bitte schließen Sie das Fenster.'
  }

}

@Component({
  selector: 'toycc-error-page',
  templateUrl: './error-page.component.html',
  standalone: false
})
export class ErrorPageComponent {
  code: string
  message: string
  constructor(
    private route: ActivatedRoute
  ) {
    const data = this.route.snapshot.data
    this.code = data['code']
    this.message = data['message']
  }
}
